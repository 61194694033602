import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Icons
import IconShare from "assets/icons/icon-share.inline.svg"

// Components
import Container from "components/container/"
import Button from "components/button"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints"

const StyledNewsHeader = styled.section`
  background-color: ${colors.silver__pale};
  box-sizing: border-box;

  .header__logo {
    min-width: 155px;
    height: 70px;
    background: ${colors.white};
    border-radius: 200px;
  }

  .logo__container {
    padding-top: 104px;
    margin-bottom: 40px;
  }

  button {
    background-color: ${colors.silver__extra_pale};
    &.mobile {
      width: 100%;
      margin: 18px auto 64px auto;
    }
  }

  .publish-date {
    ${breakpoint.small`
    padding-bottom: 138px;
    `}
  }
`

const NewsHeader = (props) => {
  const { title, outletLogo, publishDate } = props

  const image = getImage(outletLogo)

  return (
    <StyledNewsHeader>
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div className="logo__container d-flex align-items-center justify-content-between">
              <div className="header__logo d-flex justify-content-center align-items-center">
                <GatsbyImage image={image} alt="" />
              </div>

              <Button
                type="button"
                level="tertiary"
                className="d-none d-sm-block"
              >
                Share
                <IconShare className="ms-2" />
              </Button>
            </div>

            <h1 className="h2 maxi mb-3">{title}</h1>
            <p className="publish-date text--large">{publishDate}</p>
            <Button type="button" level="tertiary" className="mobile d-sm-none">
              Share
              <IconShare className="ms-2" />
            </Button>
          </div>
        </div>
      </Container>
    </StyledNewsHeader>
  )
}

export default NewsHeader

NewsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  outletLogo: PropTypes.shape({}).isRequired,
  publishDate: PropTypes.string.isRequired,
}
