import React from "react"

// Libraries
import { graphql } from "gatsby"
import Layout from "layouts/layout-primary"
import styled from "styled-components"
import PropTypes from "prop-types"

// Components
import NewsHeader from "components/news-header"
import RichContent from "components/rich-content"

// Utils
import breakpoints from "utils/breakpoints"

const StyledNews = styled.section`
  .content {
    padding: 64px 0;

    ${breakpoints.small`
    padding: 94px 0 199px 0;
  `}
  }
`

const News = (props) => {
  const {
    data: { contentfulNews: data },
  } = props

  return (
    <Layout>
      <StyledNews>
        <NewsHeader {...data} />
        <div className="content">{data && <RichContent {...data} />}</div>
      </StyledNews>
    </Layout>
  )
}

export default News

export const query = graphql`
  query ($id: String!) {
    contentfulNews(id: { eq: $id }) {
      id
      title
      slug
      publishDate(formatString: "MMM D, yyyy")
      outletLogo {
        gatsbyImageData(height: 40, quality: 100)
      }
      content {
        raw
      }
    }
  }
`

News.propTypes = {
  data: PropTypes.shape({
    contentfulNews: PropTypes.shape({
      title: PropTypes.string.isRequired,
      outletLogo: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
}
